import moment from "moment";

export interface IBackendWorkOrderUser{
	id        : number;
	order_id  : number;
	user_id   : number;
	assigned_by : number;
	assigned_by_name : string;
	name: string;
	created_at: Date;
	updated_at: Date;
}

export interface IWorkOrderUser {
	id        : number;
	orderId  : number;
	userId   : number;
	assignedBy : number;
	assignedByName : string;
	name: string;
	createdAt: Date;
	updatedAt: Date;
}

export class WorkOrderUser implements IWorkOrderUser {
	static readonly clean = Object.freeze(new WorkOrderUser());
	id = 0;
	orderId = 0;
	userId = 0;
	assignedBy = 0;
	assignedByName = '';
	name = '';
	createdAt : Date;
	updatedAt : Date;

	parse(obj: IBackendWorkOrderUser) {

		const offset = Number(new Date().getTimezoneOffset() / 60);
		this.id       	= Number(obj.id           || WorkOrderUser.clean.id      );
		this.orderId  	= Number(obj.order_id     || WorkOrderUser.clean.orderId );
		this.userId   	= Number(obj.user_id      || WorkOrderUser.clean.userId  );
		this.assignedBy   	= Number(obj.assigned_by         || WorkOrderUser.clean.assignedBy   );
		this.assignedByName   	= String(obj.assigned_by_name      || WorkOrderUser.clean.assignedByName  );
		this.name   	= String(obj.name      || WorkOrderUser.clean.name  );
		this.createdAt	= obj.created_at ? moment(obj.created_at).toDate() : WorkOrderUser.clean.createdAt;
		this.updatedAt	= obj.updated_at ? moment(obj.updated_at).toDate() : WorkOrderUser.clean.updatedAt;

		return this;
	}

	set(obj: IWorkOrderUser) {
		Object.entries(obj).forEach(([key]) => this[key] = obj[key]);
		return this;
	}

	clear() {
		Object.entries(this).forEach(([key]) => {
			this[key] = WorkOrderUser.clean[key];
		});

		return this;
	}

	clone() {
		return new WorkOrderUser().set(this);
	}
}
