import moment from "moment";
import { WorkOrderItem, IBackendWorkOrderItem, IWorkOrderItem } from './work-order-item.model';
import { WorkOrderNote, IBackendWorkOrderNote, IWorkOrderNote } from './work-order-note.model';
import { WorkOrderUser, IBackendWorkOrderUser, IWorkOrderUser } from './work-order-user.model';
import { IBackendEquipment, Equipment, IEquipment } from '../equipment.model';

export interface IBackendWorkOrder{
	id        : number;
	equipment_id  : number;
	created_by : number;
	created_by_name? : string; //phantom variable
	status   : number;
	created_at? : Date;
	updated_at? : Date;
	equipment?: IBackendEquipment;
	notes?: IBackendWorkOrderNote[];
	items?: IBackendWorkOrderItem[];
	users?: IBackendWorkOrderUser[];
	logbook_entries?: any[];
}

export interface IWorkOrder{
	id        : number;
	equipmentId  : number;
	createdBy : number;
	status   : number;
	createdByName? : string; //phantom variable
	createdAt?: Date;
	updatedAt?: Date;
	statusType?: string;
	equipment?: IEquipment;
	notes?: IWorkOrderNote[];
	items?: IWorkOrderItem[];
	users?: IWorkOrderUser[];
	logbookEntries?: any[];
}

export class WorkOrder implements IWorkOrder {
	static readonly clean = Object.freeze(new WorkOrder());
	id = 0;
	equipmentId = 0;
	createdBy = 0;
	createdByName = ''; //phantom variable
	status = 0;
	createdAt : Date;
	updatedAt : Date;
	statusType = '';
	equipment? : Equipment;
	notes? : WorkOrderNote[] = [];
	items : WorkOrderItem[] = []; 
	users : WorkOrderUser[] = [];
	logbookEntries?: any[] = [];

	parse(obj: IBackendWorkOrder) {

		const offset = Number(new Date().getTimezoneOffset() / 60);

		this.id       	= Number(obj.id           || WorkOrder.clean.id      );
		this.equipmentId  	= Number(obj.equipment_id     || WorkOrder.clean.equipmentId );
		this.createdBy   	= Number(obj.created_by  || WorkOrder.clean.createdBy  );
		this.createdByName  = String('created_by_name' in obj ? obj.created_by_name : WorkOrder.clean.createdByName);
		this.status  	= Number(obj.status     || WorkOrder.clean.status );
		this.createdAt	= obj.created_at ? moment(obj.created_at).toDate() : WorkOrder.clean.createdAt;
		this.updatedAt	= obj.updated_at ? moment(obj.updated_at).toDate() : WorkOrder.clean.updatedAt;
		this.statusType = String(obj.status != null ? this.getStatus(obj.status) : '');
		this.equipment     = obj.equipment       ? new Equipment        ().parse(obj.equipment       ) : new Equipment        ();
		this.notes                = (obj.notes      || []).map((note ) => new WorkOrderNote().parse(note));
		this.items                = (obj.items      || []).map((item ) => new WorkOrderItem().parse(item));
		this.users                = (obj.users      || []).map((user ) => new WorkOrderUser().parse(user));
		this.logbookEntries       = ('logbook_entries' in obj ? obj.logbook_entries : WorkOrder.clean.logbookEntries);
		return this;
	}

	set(obj: IWorkOrder) {
		Object.entries(obj).forEach(([key]) => this[key] = obj[key]);
		return this;
	}

	clear() {
		Object.entries(this).forEach(([key]) => {
			this[key] = WorkOrder.clean[key];
		});

		return this;
	}

	clone() {
		return new WorkOrder().set(this);
	}

	// -------------------------------------------------- ANCHOR: helpers

	getStatus(status: number): string {
		switch (status) {
		case 0:
			return 'Open';
		case 1:
			return 'Closed';
		default:
			return '';
		}
	}
}
