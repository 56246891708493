import moment from 'moment';
import { IBackendLessonType, ILessonType, LessonType } from './lesson-type.model';

export interface IBackendItem {
	[key: string]             : any;
	created_at               ?: Date;
	updated_at               ?: Date;
	id                       ?: number;
	item_id                  ?: number;
	base_rate                ?: number;
	ninja_rate               ?: number;
	price                    ?: number;
	tax                      ?: number;
	tax_id                   ?: number;
	custom_quantity          ?: number;
	quantity_modified        ?: number;
	quantity                 ?: number;
	total                    ?: number;
	qb_id                    ?: number;
	qb_name                  ?: string;
	name                     ?: string;
	type                     ?: string;
	description              ?: string;
	source                   ?: string;
	equipment_type_id        ?: number[];
	rate_sets                ?: number[];
	syllabus_lesson_type_ids ?: number[];
	client_subtype_ids       ?: number[];
	is_custom                ?: number; // boolean
	is_invoice_visible       ?: number; // boolean
	pay_with_budget          ?: number; // boolean
	charge_per_hour          ?: number; // boolean
	invoice_default          ?: number; // boolean
	add_nonflight_hours      ?: number; // boolean
	required                 ?: number; // boolean
	is_fuel                  ?: number; // boolean
	location              	 ?: string;
	flat_rate              	 ?: number;
	in_stock              	 ?: number;
	is_included              ?: number; // boolean
	syllabus_lesson_types	 ?: IBackendLessonType[];
	tags ?: string[];
}

export interface IItem {
	[key: string]          : any;
	createdAt             ?: Date;
	updatedAt             ?: Date;
	id                    ?: number;
	itemId                ?: number;
	baseRate              ?: number;
	ninjaRate             ?: number;
	price                 ?: number;
	tax                   ?: number;
	taxId                 ?: number;
	customQuantity        ?: number;
	/**
	 * Boolean. If user did not modified quantioty, it needs to be calculated
	 * taking into consideration multiple variables
	 */
	quantityModified      ?: number;
	/** Quantity amount shown in Invoice modal */
	quantity              ?: number;
	/** Total amount shown in Invoice modal */
	total                 ?: number;
	qbId                  ?: number;
	qbName                ?: string;
	name                  ?: string;
	type                  ?: string;
	description           ?: string;
	source                ?: string;
	equipmentTypeId       ?: number[];
	rateSets              ?: number[];
	syllabusLessonTypeIds ?: number[];
	clientSubtypeIds      ?: number[];
	isCustom              ?: string;
	isInvoiceVisible      ?: string;
	payWithBudget         ?: string;
	chargePerHour         ?: string;
	invoiceDefault        ?: string;
	addNonflightHours     ?: string;
	fuel                  ?: string;

	isIncluded		       ?: boolean;
	isPayWithBudget       ?: boolean;
	isChargePerHour       ?: boolean;
	isInvoiceDefault      ?: boolean;
	isAddNonflightHours   ?: boolean;
	required              ?: boolean;
	isFuel                ?: boolean;
	syllabusLessonTypes   ?: ILessonType[];
	location              ?: string;
	flatRate              ?: string;
	isFlatRate            ?: boolean;
	inStock 			  ?: number;
	tags ?: string[];
}

export class Item implements IItem {
	static readonly clean = Object.freeze(new Item());
	[key: string]          : any;
	createdAt             ?: Date;
	updatedAt             ?: Date;
	id                    = 0;
	itemId                = 0;
	baseRate              = 0;
	/**
	 * * type: 'Inventory' & 'Non-Inventory'
	*/
	ninjaRate             = 0;
	price                 = 0;
	tax                   = 0;
	taxId                 = 0;
	customQuantity        = 0;
	quantityModified      = 0;
	quantity              = 0;
	total                 = 0;
	qbId                  = 0;
	qbName                = '';
	name                  = '';
	/** Service, Inventory, Non-Inventory, Bundle/Group */
	type                  = '';
	description           = '';
	source                = '';
	equipmentTypeId       = [];
	/**
	 * * type: 'Service' only
	 * * Array (rate_set IDs)
	 */
	rateSets              = [];
	/**
	 * * type: 'Service' only
	 * * Array (lesson type IDs)
	 */
	syllabusLessonTypeIds = [];
	/**
	 * * type: 'Inventory' & 'Non-Inventory'
	 * * Array (client subtype IDs)
	 */
	clientSubtypeIds      = [];
	isCustom              = '0';
	isInvoiceVisible      = '0';
	payWithBudget         = '0';
	chargePerHour         = '0';
	invoiceDefault        = '0';
	addNonflightHours     = '0';
	fuel                  = '0';

	isIncluded            = false;
	isPayWithBudget       = false;
	isChargePerHour       = false;
	isInvoiceDefault      = false;
	isAddNonflightHours   = false;
	/** Required items are not removable */
	required              = false;
	isFuel                = false;
	syllabusLessonTypes	  : LessonType[] = [];
	location = '';
	flatRate = '0';
	isFlatRate = false;
	inStock = 0;
	tags?: string[];


	parse(obj: IBackendItem) {
		this.createdAt             = obj.created_at ? moment.utc(obj.created_at ).toDate() : Item.clean.createdAt ;
		this.updatedAt             = obj.updated_at ? moment.utc(obj.updated_at ).toDate() : Item.clean.updatedAt ;
		this.id                    = Number  ('id'                        in obj ?  obj.id                                      : Item.clean.id                    );
		this.itemId                = Number  ('item_id'                   in obj ?  obj.item_id                                  : Item.clean.itemId                );
		this.baseRate              = Number  ('base_rate'                 in obj ?  obj.base_rate                               : Item.clean.baseRate              );
		this.ninjaRate             = Number  ('ninja_rate'                in obj ?  obj.ninja_rate                              : Item.clean.ninjaRate             );
		this.price                 = Number  (
			'price' in obj ? obj.price :
				'ninja_rate' in obj ? obj.ninja_rate :
					'base_rate' in obj ? obj.base_rate :
						Item.clean.price
		);
		this.tax                   = Number  ('tax'                       in obj ?  obj.tax                                     : Item.clean.tax                   );
		this.taxId                 = Number  ('tax_id'                    in obj ?  obj.tax_id                                  : Item.clean.taxId                 );
		this.customQuantity        = Number  ('custom_quantity'           in obj ?  obj.custom_quantity                         : Item.clean.customQuantityAmount  );
		this.quantityModified      = Number  ('quantity_modified'         in obj ?  obj.quantity_modified                       : Item.clean.quantityModified      );
		this.quantity              = Number  ('quantity'                  in obj ?  obj.quantity                                : Item.clean.quantity              );
		this.total                 = Number  ('total'                     in obj ?  obj.total                                   : Item.clean.total                 );
		this.qbId                  = Number  ('qb_id'                     in obj ?  obj.qb_id                                   : Item.clean.qbId                  );
		this.qbName                = String  ('qb_name'                   in obj ?  obj.qb_name                                 : Item.clean.qbName                );
		this.name                  = String  ('name'                      in obj ? (obj.name        || Item.clean.name        ) : Item.clean.name                  );
		this.type                  = String  ('type'                      in obj ? (obj.type        || Item.clean.type        ) : Item.clean.type                  );
		this.type                  = this.type === 'NonInventory' ? 'Non-Inventory' : this.type;
		this.description           = String  ('description'               in obj ? (obj.description || Item.clean.description ) : Item.clean.description           );
		this.source                = String  ('source'                    in obj ? (obj.source      || Item.clean.source      ) : Item.clean.source                );
		this.equipmentTypeId       =         ('equipment_type_id'         in obj ?  obj.equipment_type_id                       : Item.clean.equipmentTypeId       );
		this.rateSets              =         ('rate_sets'                 in obj ?  obj.rate_sets                               : Item.clean.rateSets              );
		this.syllabusLessonTypeIds =         ('syllabus_lesson_type_ids'  in obj ?  obj.syllabus_lesson_type_ids                : Item.clean.syllabusLessonTypeIds );
		this.clientSubtypeIds      =         ('client_subtype_ids'        in obj ?  obj.client_subtype_ids                      : Item.clean.clientSubtypeIds      );
		this.isCustom              = String  ('is_custom'                 in obj ?  obj.is_custom                               : Item.clean.isCustom              );
		this.isInvoiceVisible      = String  ('is_invoice_visible'        in obj ? (obj.is_invoice_visible  ? '1' : '0'        ) : Item.clean.isInvoiceVisible     );
		this.payWithBudget         = String  ('pay_with_budget'           in obj ? (obj.pay_with_budget     ? '1' : '0'        ) : Item.clean.payWithBudget        );
		this.isIncluded            = Boolean ('is_included'               in obj ?  obj.is_included                             : Item.clean.isIncluded            );
		this.chargePerHour         = String  ('charge_per_hour'           in obj ? (obj.charge_per_hour     ? '1' : '0'        ) : Item.clean.chargePerHour        );
		this.invoiceDefault        = String  ('invoice_default'           in obj ? (obj.invoice_default     ? '1' : '0'        ) : Item.clean.invoiceDefault       );
		this.addNonflightHours     = String  ('add_nonflight_hours'       in obj ? (obj.add_nonflight_hours ? '1' : '0'        ) : Item.clean.addNonflightHours    );
		this.fuel                  = String  ('is_fuel'                   in obj ? (obj.is_fuel             ? '1' : '0'        ) : Item.clean.fuel                 );
		this.isIncludedEquipment   = Boolean ('is_included_equipment'     in obj ?  obj.is_included_equipment                   : Item.clean.isIncludedEquipment   );
		this.isPayWithBudget       = Boolean ('pay_with_budget'           in obj ?  obj.pay_with_budget                         : Item.clean.isPayWithBudget       );
		this.isChargePerHour       = Boolean ('charge_per_hour'           in obj ?  obj.charge_per_hour                         : Item.clean.isChargePerHour       );
		this.isInvoiceDefault      = Boolean ('invoice_default'           in obj ?  obj.invoice_default                         : Item.clean.isInvoiceDefault      );
		this.isAddNonflightHours   = Boolean ('add_nonflight_hours'       in obj ?  obj.add_nonflight_hours                     : Item.clean.isAddNonflightHours   );
		this.required              = Boolean ('required'                  in obj ?  obj.required                                : Item.clean.required              );
		this.isFuel                = Boolean ('is_fuel'                   in obj ?  obj.is_fuel                                 : Item.clean.isFuel                );
		this.syllabusLessonTypes   =         (obj.syllabus_lesson_types || []).map((lesson)=> new LessonType().parse(lesson));
		this.location              = String  ('location'                   in obj ?  obj.location                               : Item.clean.location              );
		this.flatRate        	   = String  ('flat_rate'           in obj ? (obj.flat_rate     ? '1' : '0'        ) : Item.clean.flatRate       );
		this.isFlatRate      	   = Boolean ('flat_rate'           in obj ?  obj.flat_rate                         : Item.clean.isFlatRate      );
		this.inStock               = Number  ('in_stock'                   in obj ?  obj.in_stock                               : Item.clean.inStock               );
		this.tags                  = 'tags' in obj ? this.getTags(obj.tags) : [];
		return this;
	}

	set(obj: IItem) {
		Object.entries(obj).forEach(([key]) => this[key] = obj[key]);

		return this;
	}

	clear() {
		Object.entries(this).forEach(([key]) => {
			this[key] = Item.clean[key];
		});

		return this;
	}

	clone() {
		return new Item().set(this);
	}

	getTags(back_tags) : string[] {
		const tags = [];

		back_tags.forEach(element => {
			tags.push(element.tag)
		});

		return tags;
	}
}
