export const environment = {
	production: false,
	// gstcLicenseKey: '====BEGIN LICENSE KEY====\nYYuWKNuEHjIQNL/l/beDxxOtriW9+ynJO55qClY0arDUJ3XhzH5+oa+2e8iv7yxkvqgrIhGfXZe+WI5/h6yN11j9WrFBmBQriymnyFT9XNG/lN0KqMuV/qwoimXIMf8lRbfAsvJB+W5XPZlSFgggm13Gkz+hw9byAPjvuo3+lohyS06caFYBfBLDGFJZb5bgR9Si0I32yW6h5f+xgsOi+EFnJe5eqXlZXEHZHpOViZMdvXnr5N02ULbsec4l+yuGBq+3elZYTuWYSRXxq++tEaDkQgAKQtC6BPKEhIiUnHY1bP3wGMXwDF0/s9eoN+erGiNadyB45F4T0zdnOX4jAw==||U2FsdGVkX180hrakyAODpMIVPdlo78Z0skd7L5RrHg2CNKw0hGR+iun1VkOlRxqcEJJj96P6qcv1w8pDGsWNidsjJEZeM3MO7rT0OnWRN5s=\nYW34z8V1qTql0T04bMfkHDxFT3Z6JKO9FZpohaNWQqfKEEgJbs2q66+yUvCugEhoCsbabxYVDz+S0SIYz7xeTKn3+DosSgpn/Lqb1lTcI0s26EoUB8TuSHIUpMoQmdLIGSqk/c63J8/Y3BxOSKXDDiFs03FbMbGgL4SB7xdGmK4dncSZlEsU1KMOKQbvUTKdbTIXdnTo+Y+EIY2hAMdh/E7fNZsW0i96U3pNPeiaFua8fbfT9f9m/ms3/jRj7YyW0VWWy3vj1eac6VJoM93kpx1NYH/WB/xdluHo1Nv8gExPJlL8seeJKpnimBu+Nc/ONaPrnuLtD4XYM3eEPb5NDw==\n====END LICENSE KEY====',

	// gstcLicenseKey: '====BEGIN LICENSE KEY====\nhuNDzCTh37n9TXIbMvFU6sXV6wYmoetwvZZyxVSP6tj70fPZiqgd16+rmh3naS9gQu/VvZNCy/EoPNWRfu+VXruUWxforkB8YXefXPhmySQuDm0XVLsH7ETJrR6rTLfqXS3eRFvwwpaCu4vFDoMNoWzWJEwBk6TOLIn9aY1gROcMHhW4L7xbKnEigXcnTe9IcQg1Lsw+LaKiCy56itkrKkgIxmGqNLr4JpdeR2Ssi0wPyREheiISGOV7coRCh+zL45b8Fu3qwEj/w8wspQnRw8tq4MOJgyrb5ULZAcdJyWeo2smamb725SpUxcZufZueN1lfp4hbYoGg+ZYlMSUTuA==||U2FsdGVkX19KKjK6uZzldnyToQO6bgt+zslE+2QBG+aCzPrlV3g4oFhY6JAAXR3S3hWhFZYjzvCa4SYOb0UkmrgB73I/ZP5KYUQex+Wwxlk=\neNYfwgNCpkOq53Khn+4hlfXN+NJEg/K0VwewwTIzDsrcQ/gqaf41Zo/mkItbRrbUJ2dK1JYzJpSEpsbMtxCNkrJhquAOo7Xl9AJrZO6bqiRqYaASOwEP2SXmcIqIrMYExceu3MQArXps9lr9u72bTCx5d2P5QkSc5QFtXBpmbVm7NmlA1irAeqJ99eC2Oj7gJ0ssCZGbrbS95IKm7UT7XFj49EPWY6MssK4d2OghWlKkKLWoV3s4EpVowV3USS/DbjI9Q+zEV0EMBp10P+6Y73zUDbWVwYyJSv3foaBA2NEW8rxnpgneZKJN4LxygoEQ8qK+vH7Cb3Aykb5mxEatfg==\n====END LICENSE KEY====',
	// // local testing ^

	gstcLicenseKey: 'GSTC:3:dvDnHCaRzDU=:Bt/DxUAzTUhFgaO5:IY5fnRsaUCshOAIUcpprD/nt/JcyLHyGAw+AJADXHtIlvo26OoS62zwe6MGnh08n5cvqeqz9+rCeb1tqSnTBpnGZEi395+JzCB2EXoWFaAb2YqvRZwRXVYe1OipdY1o5PcCGII+OygAgw/GYV4OoZVV66S13AbzHOhAZ4iXjIVUfnJ71AiM4hKPDjQHHwagZtyw+qZK1W/wAMNICPQpFr3uEMu6oCcGhioA5mKaSJMWrgDuKmenI9l8Mrx+ncRxp4zlm3I8aU4ta032en07wPhTkXtYt+r78vcnG6ZcF47TV/3gnk808jVXS5PYO/w4+WuF9YKojATTnQ+k5vWA84NMtHGZfGGCBN7xL/qnkPCQlY0kQS54oLeJythOa6dMCx/36C/kPmSgewggxK6YotRGCU8NufEb9v10a+fst3BmEA35wLkhidyAKDD0zygFQKrIChc2LPH6zefVSfgczQwTap891S1/DUiTYzNrC7BdB2faFyCtMIhkfFstaKy2kySgsvibzt/9eFo0jWAQtj9tdJAywJHCtNfH8Oat1HO82a4+y9VPhof5qKpeovwAk8qfs/WNLi+5APyhNgEbAQCoMiuC7EVVRf67ahKAVX8JYYKjezXVWoLg3U0NOQen98wlyy8q7R8/7ejNBrQOoKJIUkEA5WutchZjj98G6CrlqKtKgkywYoTGT9ZXsE/GWerlAY/gxEAjnl/v2uftoYYPPzvVH0SrO5TnhDAWelxTA2Ppnt3bY80d4SCtKvnE6IfHYRldzw80P7kJ7aSkDo0U4gtgiiBLuhiGaGzkzUngXsAql74W7Uxjc4wVvSkIn7YhPxnh/O6KRS4ohuWG595OzkOnecGd+GrbI6fxlCf9CHkvgcwldzzwf+Bio1vDqBq9LGFiKbtUP4vjV/9PDNHy5Pls3UM/0jIDVbam4gx9xbti2RtKBc2pCHo8hoYdJlIAjBuWF3fhsMlAtdjcLDA3t+y+gR+IwYpctGyE15KfpI/gQP/X3mu8MPtFaCwa3jVlPnt2JZmRNAb/1kkflluYvtMYomUlS5mV/we68PuL5Ph8lZUs8XV22K9nXcvw6JJrU9hwAOwBe0VW74Y/Z1+AUe2g1rZXm73+6ZnqBIK0z',
	// purchased ^

	// saritasa development
	apiUrl: 'https://api.flightninja.saritasa.rocks/',       // development
	apiAssetsUrl: 'https://api.flightninja.saritasa.rocks/', // development
	schoolLocation: 'SANDBOX?'

	// apiUrl: 'https://api.flight-ninja.com/',
	// apiAssetsUrl: 'https://api.flight-ninja.com/', // production
	// schoolLocation: ''
};
