import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthenticationGuard } from './_core/services/auth/guards/authentication.guard';

const routes: Routes = [
	{
		path: 'login',
		loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
	},
	{
		path: 'reset-token/:token',
		loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
	},
	{
		path: 'enrollment-questionnaire/:token',
		loadChildren: () => import('./enrollment-questionnaire/enrollment-questionnaire.module').then(m => m.EnrollmentQuestionnaireModule)
	},
	{
		path: 'app',
		loadChildren: () => import('./wrapper/wrapper.module').then(m => m.WrapperModule),
		canActivate: [AuthenticationGuard],
		runGuardsAndResolvers: 'always'
	},
	{
		path: '**',
		redirectTo: 'login'
	},
];


@NgModule({
	imports: [RouterModule.forRoot(routes, {
		onSameUrlNavigation: 'reload',
		canceledNavigationResolution: 'computed',
	})],
	exports: [RouterModule]
})
export class AppRoutingModule { }
