<div class="snack-container" (mouseenter)="mouseEnter()" (mouseleave)="mouseLeave()">
	<div class="info-container">
		<div class="message-container">
			<p class="message-container__msg" [innerHTML]="data.message"></p>
		</div>

		<div class="buttons-container">
			<button class="close-snack" (click)="action()">
				<img src="assets/img/icons/toast_close.svg" alt="" />
			</button>
		</div>
	</div>
</div>
