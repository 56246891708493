import { IEvent, Event, IBackendEvent } from './event.model';

import moment from "moment";

export interface IBackendCheckrideRequest {
	id: number;
	client_id: number;
	client?: {
		id: number,
		full_name: string,
		location: number
	};
	user_id: number;
	course_id: number;
	course?:{
		id: number,
		title: string,
		abbreviation?: string
	};
	regulation?: number;
	aircraft: number;
	initial_pool_id?: number | null;
	pool_id?: number | null;
	wishlist?: number | null;
	status: number;
	event_id?: number | null;
	created_at: Date;
	days_since_created: number;
	updated_at: Date;
	created_by: {
		id: number,
		full_name: string
	};
	updated_by: {
		id: number,
		full_name: string
	};
	instructor_name?: string;
	notes?: IBackendCheckrideNote[];
	location_name?: string;
	event?: IBackendEvent,
	updatedcr_by_name?: string;
	color?: string;
	groundTime?: number,
	details?: string
}
  
export interface IBackendCheckrideNote {
	id: number;
	checkride_request_id: number;
	note: string;
	created_at: Date;
	updated_at: Date;
	created_by: {
		id: number,
		full_name: string
	};
}

export interface ICheckrideRequest {
	id: number;
	clientId: number;
	client?: {
		id?: number,
		fullName: string,
		location: number
	};
	userId: number;
	courseId: number;
	course?:{
		id: number,
		title: string,
		abbreviation?: string
	};
	regulation?: number;
	aircraft: number;
	poolId?: number | null;
	initialPoolId?: number | null;
	wishlist?: number | null;
	status: number;
	eventId?: number | null;
	createdAt: Date;
	daysSinceCreated: number;
	updatedAt: Date;
	createdBy?: {
		id: number,
		fullName: string
	};
	updatedBy?: {
		id: number,
		fullName: string
	};
	instructorName?: string;
	notes?: ICheckrideNote[];
	locationName?: string;
	event?: Event;
	updatedCrName?: string;
	color?: string;
	groundTime?: number;
	details?: string;
}
  
export interface ICheckrideNote {
	id: number;
	checkrideRequestId: number;
	note: string;
	createdAt: Date;
	updatedAt: Date;
	createdBy: {
		id: number,
		fullName: string
	};
}


export class CheckrideRequest implements ICheckrideRequest {
	static readonly clean = Object.freeze(new CheckrideRequest());
	id = 0 ;
	clientId = 0;
	client = {
		id: 0,
		fullName: '',
		location: 0
	};
	userId = 0;
	courseId = 0;
	course = {
		id: 0,
		title: '',
		abbreviation: ''
	};
	regulation = 2;
	aircraft = 0;
	poolId = 0;
	initialPoolId = 0;
	wishlist = 0;
	status = 0;
	eventId = 0;
	createdAt: Date;
	daysSinceCreated = 0;
	updatedAt: Date;
	updatedBy = {
		id: 0,
		fullName: ''
	};
	createdBy = {
		id: 0,
		fullName: ''
	};
	instructorName = '';
	notes: CheckrideRequestNote[] = [];
	locationName = '';
	event?: Event;
	updatedCrName? = '';
	color? = '';
	groundTime? = 0;
	details? = '';

	parse(obj: IBackendCheckrideRequest) {

		this.id       	= Number(obj.id           || CheckrideRequest.clean.id      );
		this.clientId	= Number(obj.client_id           || CheckrideRequest.clean.clientId      );
		//String('vip'      in obj ? (obj.vip ? '1' : '0')   : User.clean.vip );

		this.client 	= 'client' in obj ? {
			id : Number(obj.client.id           || CheckrideRequest.clean.client.id      ),
			fullName : String(obj.client.full_name           || CheckrideRequest.clean.client.fullName      ),
			location: Number(obj.client.location           || CheckrideRequest.clean.client.location      )
		} : CheckrideRequest.clean.client

		this.userId		= Number(obj.user_id           || CheckrideRequest.clean.userId      );
		this.courseId	= Number(obj.course_id           || CheckrideRequest.clean.courseId      );

		this.course 	= 'course' in obj ? {
			id : Number(obj.course.id           || CheckrideRequest.clean.course.id      ),
			title : String(obj.course.title           || CheckrideRequest.clean.course.title      ),
			abbreviation : String(obj.course.abbreviation           || CheckrideRequest.clean.course.abbreviation      ),
		} : CheckrideRequest.clean.course;

		this.regulation  =  'regulation' in obj ? Number(obj.regulation     || CheckrideRequest.clean.regulation )
		: CheckrideRequest.clean.regulation

		this.aircraft   	= Number(obj.aircraft      || CheckrideRequest.clean.aircraft  );
		this.poolId		= Number(obj.pool_id           || CheckrideRequest.clean.poolId      );
		this.initialPoolId		= Number(obj.initial_pool_id           || CheckrideRequest.clean.initialPoolId      );
		this.wishlist		= Number(obj.wishlist           || CheckrideRequest.clean.wishlist      );
		this.status		= Number(obj.status           || CheckrideRequest.clean.status      );
		this.eventId  	= Number(obj.event_id     || CheckrideRequest.clean.eventId );
		
		this.createdBy  = 'created_by' in obj ? {
			id : Number(obj.created_by.id           || CheckrideRequest.clean.createdBy.id      ),
			fullName : String(obj.created_by.full_name           || CheckrideRequest.clean.createdBy.fullName      )
		} : CheckrideRequest.clean.createdBy

		this.updatedBy  	= 'updated_by' in obj ?  {
			id : Number(obj.updated_by.id           || CheckrideRequest.clean.updatedBy.id      ),
			fullName : String(obj.updated_by.full_name           || CheckrideRequest.clean.updatedBy.fullName      )
		} : CheckrideRequest.clean.updatedBy

		this.instructorName  =  'instructor_name' in obj ? String(obj.instructor_name     || CheckrideRequest.clean.instructorName )
		: CheckrideRequest.clean.instructorName

		this.createdAt	= 	obj.created_at ? moment(obj.created_at).toDate() : CheckrideRequest.clean.createdAt;

		this.daysSinceCreated	= 'days_since_created' in obj ? Number(obj.days_since_created  || CheckrideRequest.clean.daysSinceCreated  )
		: CheckrideRequest.clean.daysSinceCreated

		this.updatedAt	= 	obj.updated_at ? moment(obj.updated_at).toDate() : CheckrideRequest.clean.updatedAt;
		this.notes = (obj.notes || []).map((element:any) => new CheckrideRequestNote().parse(element));

		this.locationName  =  'location_name' in obj ? String(obj.location_name     || CheckrideRequest.clean.locationName )
		: CheckrideRequest.clean.locationName

		this.event = 'event' in obj ?  new Event().parse(obj.event) : new Event ();

		this.updatedCrName = 'updatedcr_by_name' in obj ? String(obj.updatedcr_by_name  || CheckrideRequest.clean.updatedCrName )
		: CheckrideRequest.clean.updatedCrName;

		this.color = 'color' in obj ? String(obj.color  || CheckrideRequest.clean.color )
		: CheckrideRequest.clean.color 

		this.groundTime	= 'groundTime' in obj ? Number(obj.groundTime  || CheckrideRequest.clean.groundTime  )
		: CheckrideRequest.clean.groundTime

		this.details = 'details' in obj ? String(obj.details  || CheckrideRequest.clean.details )
		: CheckrideRequest.clean.details 

		return this;
	}

	set(obj: ICheckrideRequest) {
		Object.entries(obj).forEach(([key]) => this[key] = obj[key]);

		return this;
	}

	clear() {
		Object.entries(this).forEach(([key]) => {
			this[key] = CheckrideRequest.clean[key];
		});

		return this;
	}

	clone() {
		return new CheckrideRequest().set(this);
	}

	
}

export class CheckrideRequestNote implements ICheckrideNote {
	static readonly clean = Object.freeze(new CheckrideRequestNote());
	id = 0;
	checkrideRequestId = 0;
	note = '';
	createdAt: Date;
	updatedAt: Date;
	createdBy = {
		id: 0,
		fullName: ''
	};

	parse(obj: IBackendCheckrideNote){
		this.id       	= Number(obj.id           || CheckrideRequestNote.clean.id      );
		this.checkrideRequestId       	= Number(obj.checkride_request_id           || CheckrideRequestNote.clean.checkrideRequestId      );
		this.createdBy  	= {
			id : Number(obj.created_by.id           || CheckrideRequestNote.clean.createdBy.id      ),
			fullName : String(obj.created_by.full_name           || CheckrideRequestNote.clean.createdBy.fullName      )
		}
		this.note  = String(obj.note     || CheckrideRequestNote.clean.note );
		this.createdAt	= 	obj.created_at ? moment(obj.created_at).toDate() : CheckrideRequestNote.clean.createdAt;
		this.updatedAt	= 	obj.updated_at ? moment(obj.updated_at).toDate() : CheckrideRequestNote.clean.updatedAt;

		return this;
	}

	set(obj: ICheckrideNote) {
		Object.entries(obj).forEach(([key]) => this[key] = obj[key]);

		return this;
	}

	clear() {
		Object.entries(this).forEach(([key]) => {
			this[key] = CheckrideRequestNote.clean[key];
		});

		return this;
	}

	clone() {
		return new CheckrideRequestNote().set(this);
	}
}
