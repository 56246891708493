import moment from "moment";

export interface IBackendWorkOrderNote{
	id        : number;
	order_id  : number;
	user_id   : number;
	note	  : string;
	note_by   : string; //phantom variable
	created_at: Date;
	updated_at: Date;
}

export interface IWorkOrderNote {
	id        : number;
	orderId  : number;
	userId   : number;
	note     : string;
	noteBy   : string;
	createdAt: Date;
	updatedAt: Date;
}

export class WorkOrderNote implements IWorkOrderNote {
	static readonly clean = Object.freeze(new WorkOrderNote());
	id = 0;
	orderId = 0;
	userId = 0;
	note = '';
	noteBy = '';
	createdAt : Date;
	updatedAt : Date;

	parse(obj: IBackendWorkOrderNote) {

		const offset = Number(new Date().getTimezoneOffset() / 60);
		//this.createdAt	= 		 obj.created_at ? moment(obj.created_at).subtract(offset, 'hours').toDate() : Note.clean.createdAt;
		//this.updatedAt	= 		 obj.updated_at ? moment(obj.updated_at).subtract(offset, 'hours').toDate() : Note.clean.updatedAt;

		this.id       	= Number(obj.id           || WorkOrderNote.clean.id      );
		this.orderId  	= Number(obj.order_id     || WorkOrderNote.clean.orderId );
		this.userId   	= Number(obj.user_id      || WorkOrderNote.clean.userId  );
		this.note   	= String(obj.note         || WorkOrderNote.clean.note   );
		this.noteBy   	= String(obj.note_by      || WorkOrderNote.clean.noteBy  );
		this.createdAt	= obj.created_at ? moment(obj.created_at).toDate() : WorkOrderNote.clean.createdAt;
		this.updatedAt	= obj.updated_at ? moment(obj.updated_at).toDate() : WorkOrderNote.clean.updatedAt;

		return this;
	}

	set(obj: IWorkOrderNote) {
		Object.entries(obj).forEach(([key]) => this[key] = obj[key]);
		return this;
	}

	clear() {
		Object.entries(this).forEach(([key]) => {
			this[key] = WorkOrderNote.clean[key];
		});

		return this;
	}

	clone() {
		return new WorkOrderNote().set(this);
	}
}
